// @ts-nocheck
/* Libraries */
import dayjs from 'dayjs';
import router from 'next/router';

/* Store */
import useResponsiveStore from '@store/client/store.responsive';

/* Utils */
import {sleep} from '@utils/helpers';
import {noop} from '@utils/event';
import {Logger} from '@utils/logger';
import {checkIsBotUA, isBrowser} from '@utils/ui';
import segmentAnonymousId from '@utils/cookies/segmentAnonymousId';

type AttrKey = '_page' | '_name';
type AttrValue = string;

/** Class representing Analytic Segments. */
class ClassSegment {
  readonly debounceRate: number;

  private _page: string;

  public timer: number;

  public _name: string;

  public _gate: Map<string, number>;

  public _id: string;

  /**
   * Create a Segment.
   * @param {number} debounceRate - The x value.
   * @param {string} _name - Represents our track firing.
   * @param {string} _page - Represent our page firing
   * @param {object} _gate - A Map() to associate timers with string identifier
   * @param {number} _id - Our user advertiser id
   */
  public constructor({rate = 1000} = {}) {
    this.debounceRate = rate;
    this.timer = 300;
    this._name = '';
    this._page = '';
    this._gate = new Map();
    this._id = '';

    /*
     Lets create a fallback if the analytics is ever down, or removed.
     This lets us continue on w/o UI errors and still chain w/o issue.
     We are 'Trapping".
     */
    return new Proxy(this, {
      get(target, key, value) {
        if (!(key in target)) {
          target[key] = () => value;

          return target[key];
        }

        return target[key];
      },
    });
  }

  sanitize(name = '') {
    this._name = name.replace(/[^0-9a-z-_\s]/gi, '').trim();
    return this;
  }

  /**
   * Identify analytics request
   * @param{integer} identifier - AID of the user. A getter serves as a fallback
   * @param {object} traits - traits object if you want to add it. - always have a traits object for this.
   * */
  identify(identifier = this.identifyId, traits = {}) {
    this.identifyId = identifier;
    const mergedTraits = {...traits, ...this.utmParams};

    return this.pipeline({m: 'identify', identifier, traits: mergedTraits, fn: () => true});
  }

  /**
   * Track analytics request
   * evented method
   * @param{string} identifier - event name you want to track.
   * @param {object} traits - traits object if you want to add it.
   *
   * */
  track(identifier = '', traits = {}) {
    if (!Object.keys(traits).length || !identifier) {
      return this;
    }

    this.attribute = {attr: '_name', value: identifier};
    return this.pipeline({m: 'track', identifier, traits});
  }

  /**
   * Page analytics request
   * Lets check to see if our new request for page is different from current saved page request
   *
   * @param{string} identifier - page name you want to track.
   * @param {object} traits - traits object if you want to add it.
   * */
  page(identifier = '', traits = {}) {
    if (identifier && Object.is(identifier, this._page)) {
      return this;
    }
    this.attribute = {attr: '_page', value: identifier};
    const mergedTraits = {...traits, ...this.queryParamsFromRoute, path: identifier, ...this.utmParams};

    return this.pipeline({m: 'page', identifier, traits: mergedTraits});
  }

  /**
   * GROUP is a piggyback method. It will always fire when identify fires.
   *
   * @param identifier - AID if passed
   * @param traits - we parasite this object off of Segment::identify
   * @returns {*}
   */
  group(traits = {}) {
    return this.pipeline({
      m: 'group',
      identifier: this.identifyId,
      traits,
      force: true,
    });
  }

  /**
   * This serves as a gateway to firing the requested methods as long as the
   * nothing is gated.
   * @params{object}
   *   @type
   *     @params{string} m - method key off of analytics object we want to fire
   *     @params{string|number} identifier - user id or evented/page name
   *     @params{object} traits - key/values. Same object type in all other methods.
   *     @params{function} fn - if method requires any additional gating. Use this additional fn.
   *     @params{boolean} force - this is an explicit non-debounce override. This is used if you
   *     want to always force a fire.
   * */
  // @ts-ignore
  pipeline({m, identifier, traits, fn = () => {}, integrations = {}, force = false}) {
    /* Prevent bots from firing any segment analytics */
    if (checkIsBotUA()) return this;

    if ((this.debounce() && identifier) || fn() || force) {
      const mergedTrait = {...traits, ...{_app: 'nextjs', _deviceType: useResponsiveStore.getState().deviceType, _anonymousId: segmentAnonymousId()}};
      try {
        const mergedData = [mergedTrait, integrations].filter(obj => Object.keys(obj).length);
        sleep(this.timer).then(() => {
          try {
            const derivedFN = (isBrowser() && (window as any).analytics[m]) || noop;
            return derivedFN(identifier, ...mergedData);
          } catch (e) {
            return {};
          }
        });
      } catch (e) {
        Logger('Segment')(e);
      }
    }

    return this;
  }

  /**
   *  @param{method} done - this traps the time an interaction was fired. Use this if you want to debounce
   * the interactions. Currently no repeats happen within 1000ms
   * ex: SEGMENT.track('Some Key Name').done();
   * */
  done() {
    this._gate.set(this.gateName, +new Date());
  }

  /**
   *  @param{method} debounce - needs to be used with this.done. If a request for an action happens multiple times
   *  within the debounceRate. We only allow 1 to fire thru.
   *
   * return {object}
   *
   * note: non-chainable
   * */
  debounce() {
    const tm = this._gate.get(this.gateName);

    if (tm && dayjs(+Date.now()).diff(tm, 'millisecond') < this.debounceRate) {
      return false;
    }

    return true;
  }

  /**
   * We allow for an await so subsequent actions will not interfere with the firing ie. location changes etc..
   * @param delay - Int: tells us how long we wait before we return the promise
   * @returns {Promise.<void>}
   *
   * note: non-chainable
   *
   */
  pause(delay: number): Promise<boolean> {
    return new Promise(resolve => sleep(delay).then(() => resolve(true)));
  }

  set attribute({attr, value}: {attr: AttrKey; value: AttrValue}) {
    // as Exclude<keyof ClassSegment, "debounceRate" | "_page">
    this[attr] = value;
  }

  set identifyId(id) {
    this._id = id;
  }

  get identifyId() {
    return this._id as string | number | undefined;
  }

  get gateName() {
    return `${this._name}${this._page}`;
  }

  get queryParamsFromRoute() {
    return router.query || {};
  }

  get utmParams(): Record<string, string> {
    const queryParams = this.queryParamsFromRoute;
    const {utm_adgroup: adGroup, utm_campaign: campaign, utm_term: term} = queryParams;
    return {adGroup, campaign, term, ...queryParams};
  }
}

export default new ClassSegment();
