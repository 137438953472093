import Script from 'next/script';

const AccessibeScript = () => (
  <>
    {/* Preload script */}
    <link
      rel="preload"
      href="https://acsbapp.com/apps/app/dist/js/app.js"
      as="script"
    />

    {/* Load the script without blocking the main thread */}
    <Script
      id="accessibe-init"
      src="https://acsbapp.com/apps/app/dist/js/app.js"
      strategy="lazyOnload" // Ensure it executes after page load
      onLoad={() => {
        if (window?.acsbJS && typeof window.acsbJS.init === 'function') {
          if (window?.acsbJS) {
            window.acsbJS.init({
              statementLink: '',
              footerHtml: '',
              hideMobile: false,
              hideTrigger: false,
              disableBgProcess: false,
              language: 'en',
              position: 'left',
              leadColor: '#007b83',
              triggerColor: '#007b83',
              triggerRadius: '50%',
              triggerPositionX: 'left',
              triggerPositionY: 'bottom',
              triggerIcon: 'people',
              triggerSize: 'medium',
              triggerOffsetX: 32,
              triggerOffsetY: 32,
              mobile: {
                triggerSize: 'small',
                triggerPositionX: 'right',
                triggerPositionY: 'center',
                triggerOffsetX: 16,
                triggerOffsetY: 0,
                triggerRadius: '50%',
              },
            });
          }
        }
      }}
    />
  </>
);

export default AccessibeScript;
