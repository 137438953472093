import {NextRouter} from 'next/router';
import ClassSegment from '@integrations/segment';
import {queryClient} from '@integrations/reactquery';
import * as gtag from '@integrations/analytics/google.gtag';

import leadGeneratedPolling from '@components/App/globalConcerns/app.polling.leadgen';

import {userQueryKeys} from '@store/queries/user/queries.keys';

import {User} from 'types/user.types';
/**
 * @remarks
 * This is the main entry point for all tracking, on page level. For individual events ie. track/identify, see
 * Segment class for implementation or event tracking by type @integrations/segment/*
 */

/* First load on website firing */
export const appPageTracking = (router: NextRouter, options: Record<string, boolean>) => {
  if (options.firstLoad) {
    ClassSegment?.page(router.asPath).done();
    gtag.pageview(router.asPath);
    const urlParams = ClassSegment.utmParams;
    const {term, adGroup, email} = urlParams;
    const user = queryClient.getQueryData(userQueryKeys.all) as User;

    if (user || term || adGroup || email) {
      const identifier = (user && user.id) || email || '';
      ClassSegment.identify(identifier, {
        ...urlParams,
        ...(user && {
          id: user.id,
          email: user.email,
          first_name: user.firstName,
          last_name: user.lastName,
        }),
      }).done();
    }

    /* Start up the leadgen/identify polling */
    leadGeneratedPolling();
  }
};
