import { useEffect } from 'react';
import {useRouter} from 'next/router';

/* Client State */
import {useCouponsActions} from '@store/client/store.coupons';
import { useTrackingActions, useTrackingPhone } from '@store/client/store.tracking';

/* Utils */
import {EXPIRY_14_DAYS} from '@utils/cookies/cookies.constants';
import {getPhoneNumber, PHONE_TRACKING_TYPES, setPhoneNumber} from '@utils/cookies/phone';
import {setCouponIdCookie} from '@utils/cookies/coupon';
import {getPlanSubscriberPhone} from '@scenes/LoginPage/login.cookies';
import {pushToDataLayer} from '@integrations/analytics/google.gtag';
import { getBrandingCookie } from '@utils/cookies/branding';

/**
 * Set global cookies or state depending on the route params.
 *
 * NOTE: This is global concerns, so it should be in the app.globalsettings.ts file
 */

const PhoneCoupon = () => {
  /* Hooks */
  const router = useRouter();
  const {phone_number: phoneNumber, add_coupon_id: addCouponId, phone: queryPhone} = router.query;

  /* Client State */
  const {setCoupons} = useCouponsActions();
  const clientStatePhone = useTrackingPhone();
  const {setPhoneState} = useTrackingActions();

  /* Constants */
  const userPartnerSubscription = getBrandingCookie();
  const phone = phoneNumber || queryPhone;
  const subscriptionPhoneCookie = getPlanSubscriberPhone();

  /*
    Do we have a phone in a cookie?
    The client app didn't "find" the primary. Just 'if' thru all, maybe GA wants it that way?
  */
  useEffect(() => {
    setTimeout(() => {
      Object.values(PHONE_TRACKING_TYPES).forEach(c => {
        /* Iterate by preference: see  COOKIE_NAME_PHONE */
        const cookiePhone = getPhoneNumber(c);

        if (cookiePhone) {
          /* 1. set in state: Setting outside of components also cause a resaturation: molto bene */
          setPhoneState(cookiePhone);
          /* 2. reset in cookie */
          setPhoneNumber(c, cookiePhone, EXPIRY_14_DAYS);
        }
      });
    }, 3000);
  }, []);

  /*
    2. Check if we have phone in url
       Caveate: Subscription user takes precedence over adword user
  */
  useEffect(() => {
    if (phone) {
      /* set cookie. Also, enables us to pass this to client */
      setPhoneNumber(PHONE_TRACKING_TYPES.ADWORD_PHONE, phone, EXPIRY_14_DAYS);
      /* 1. set in state */
      setPhoneState(phone as string);
    }

    if (subscriptionPhoneCookie || userPartnerSubscription?.phone) {
      /* 1. Default to newest */
      const subscriptionPhone = userPartnerSubscription?.phone || subscriptionPhoneCookie;
      /* 1. set in state */
      setPhoneState(subscriptionPhone);
    }
  }, [phone, subscriptionPhoneCookie]);

  /**
   * When the phone changes, we should send to GA
   * 1. Send to datalayer
   * Note: We get this firing from usePhone hook
   */
  useEffect(() => {
    if (clientStatePhone?.title) {
      /* 1. */
     pushToDataLayer({ phone_conversion_number: clientStatePhone.title });
    }
  }, [clientStatePhone]);

  if (addCouponId) {
    /* set cookie for 1 hour. Also, enables us to pass this to client */
    setCouponIdCookie(addCouponId as string);
    /* set in state */
    setCoupons({param: addCouponId as string});
  }

  return null;
};

export default PhoneCoupon;
