import debounce from 'lodash/debounce';

export const pageview = (url: string) => {
  if (window.gtag) {
    window?.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_KEY, {
      page_path: url,
    });
  }
};

/**
 * PLEASE NOTE: TODO: We should add a batcher just in case the event happen before the gtag is loaded
 */
/* Push to google dataLayer */
export const pushToDataLayer = debounce(
  (params: Record<string, any>) => {
    const validParams = Object.values(params).filter(v => {
      if (v === 'phone_conversion_number') {
        if (params[v] && params[v] !== 'undefined') {
          return true;
        }
        delete params[v];
        return false;
      }

      if (v) {
        return true;
      }

      return false;
    });

    if (window?.gtag && validParams.length > 0) {
      // new: G-VT5FE382F8
      window?.gtag('config', 'AW-949056333/tuMMCI-53oIBEM3mxcQD', params);
    } else if (window) {
      setTimeout(() => {
        pushToDataLayer(params);
      }, 0);
    }
  },
  500,
  {
    trailing: true,
    leading: false,
  },
);
