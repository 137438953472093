import {useEffect} from 'react';
import Script from 'next/script';
import { isBrowser } from '@utils/ui';


const FullStoryScript = () => {
  useEffect(() => {
    if (isBrowser()) {
      // Ensure FullStory initializes only when the browser is idle
      window.requestIdleCallback(() => {
        (function(m, n, e, t, l, o, g, y) {
          if (e in m) {
            if (m.console && m.console.log) {
              m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
            }
            return;
          }
          g = m[e] = function(a, b, s) {
            g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
          };
          g.q = [];

          o = n.createElement(t);
          o.async = 1;
          o.crossOrigin = 'anonymous';
          o.src = 'https://edge.fullstory.com/s/fs.js';
          y = n.getElementsByTagName(t)[0];
          y.parentNode.insertBefore(o, y);

          g.identify = function(i, v, s) {
            g(l, { uid: i }, s);
            if (v) g(l, v, s);
          };
          g.setUserVars = function(v, s) {
            g(l, v, s);
          };
          g.event = function(i, v, s) {
            g('event', { n: i, p: v }, s);
          };
          g.anonymize = function() {
            g.identify(!!0);
          };
          g.shutdown = function() {
            g('rec', !1);
          };
          g.restart = function() {
            g('rec', !0);
          };
          g.log = function(a, b) {
            g('log', [a, b]);
          };
          g.consent = function(a) {
            g('consent', !arguments.length || a);
          };
          g.identifyAccount = function(i, v) {
            o = 'account';
            v = v || {};
            v.acctId = i;
            g(o, v);
          };
          g.clearUserCookie = function() {};
        })(window, document, window['_fs_namespace'], 'script', 'user');
      });
    }
  }, []);

  return (
    <Script
      id="fullstory-script"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          window['_fs_debug'] = false;
          window['_fs_org'] = 'o-1C9TVN-na1';
          window['_fs_namespace'] = 'FS';
        `,
      }}
    />
  );
};

export default FullStoryScript;
